:root {
    font-size: 20px;
  }
  
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }
  
  p {
    margin: 0;
  }
  
  p:not(:last-child) {
    margin-bottom: 1.5em;
  }
  
  body {
    font: 1em/1.618 Inter, sans-serif;
  
    display: flex;
    align-items: center;
    justify-content: center;
    
    min-height: 100vh;
    padding: 30px;
    margin: 0;
    
    color: #224;
    background: linear-gradient(to right, #ff7e5f, #feb47b);
  }
  
  .card {
    max-width: 300px;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  
    max-width: 500px;
    height: 300px;
    padding: 35px;
  
    border: 1px solid rgba(255, 255, 255, .25);
    border-radius: 20px;
    background-color: rgba(255, 255, 255, 0.45);
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.25);
  
    backdrop-filter: blur(15px);
  }
  
  .card-footer {
    font-size: 0.95em;
    text-align: center;
    color: #446;
    font-family: "Custom-Bold";
    margin-top: 10px;
    padding: 10px;
  }

  .social-media {
    margin-left: 10px;
  }
  .social-media-text {
    text-decoration: none;
    font-family:"BoldCondensed"; 
    color:#000;
    margin-bottom:10px;
  }