@font-face {
    font-family: 'BoldCondensed';
    src: url('./fonts/SofascoreSans-BoldCondensed.ttf') format('truetype');
   
}
@font-face {
    font-family: 'Medium';
    src: url('./fonts/SofascoreSans-Medium.ttf') format('truetype');
   
}

@font-face {
    font-family: 'Custom-Bold';
    src: url('./fonts/SofascoreSans-Bold.ttf') format('truetype');
   
}